@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@400;500;600&display=swap);
html,
body,
head,
table,
thead,
tbody,
tr,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
li,
span,
a,
em,
br {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html:lang(kr),
body:lang(kr),
head:lang(kr),
table:lang(kr),
thead:lang(kr),
tbody:lang(kr),
tr:lang(kr),
td:lang(kr),
th:lang(kr),
h1:lang(kr),
h2:lang(kr),
h3:lang(kr),
h4:lang(kr),
h5:lang(kr),
h6:lang(kr),
div:lang(kr),
p:lang(kr),
ul:lang(kr),
ol:lang(kr),
li:lang(kr),
span:lang(kr),
a:lang(kr),
em:lang(kr),
br:lang(kr) {
  font-family: "Nanum Gothic", sans-serif;
}
html:lang(en),
body:lang(en),
head:lang(en),
table:lang(en),
thead:lang(en),
tbody:lang(en),
tr:lang(en),
td:lang(en),
th:lang(en),
h1:lang(en),
h2:lang(en),
h3:lang(en),
h4:lang(en),
h5:lang(en),
h6:lang(en),
div:lang(en),
p:lang(en),
ul:lang(en),
ol:lang(en),
li:lang(en),
span:lang(en),
a:lang(en),
em:lang(en),
br:lang(en) {
  font-family: "Roboto", sans-serif;
}

ol,
ul,
li {
  list-style: none;
}

* {
  font-family: "IBM Plex Sans KR", sans-serif;
}

#app-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
}

.main-area {
  flex: 1 1;
  background-color: #f2f3f7;
  height: 100vh;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: black;
}

a:link {
  color: black;
}

a:active {
  color: black;
}

a:hover {
  color: black;
}

.link {
  color: black;
}

.h1 {
  font-size: 1.32rem;
  line-height: 1.34rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.h2 {
  font-size: 1.24rem;
  line-height: 1.26rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.h3 {
  font-size: 1.24rem;
  line-height: 1.26rem;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.h4 {
  font-size: 1.1rem;
  line-height: 1.12rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.h5 {
  font-size: 1.1rem;
  line-height: 1.12rem;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.title {
  font-size: 0.88rem;
  line-height: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.sub-title {
  font-size: 0.84rem;
  line-height: 0.86rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.caption {
  font-size: 0.76rem;
  line-height: 0.78rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
}

.container {
  padding: 20px;
}

.table {
  padding: 20px;
  background-color: white;
}

.modal-info-block {
  margin-bottom: 12px;
}
.modal-info-block p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 4px;
}

.ant-input[disabled] {
  color: #8e8e8e !important;
}
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 23px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
