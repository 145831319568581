html,
body,
head,
table,
thead,
tbody,
tr,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
li,
span,
a,
em,
br {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    &:lang(kr) {
        font-family: 'Nanum Gothic', sans-serif;
    }

    &:lang(en) {
        font-family: 'Roboto', sans-serif;
    }
}

ol,
ul,
li {
    list-style: none;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@400;500;600&display=swap');
* {
    font-family: 'IBM Plex Sans KR', sans-serif;
    //   color: #000000;
}

#app-container {
    //// sidebar options
    // display: flex;
    // flex-direction: row;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
}

.main-area {
    flex: 1;
    background-color: #f2f3f7;
    height: 100vh;
    overflow-y: scroll;
}

a {
    text-decoration: none;
    color: black;
}

a:link {
    color: black;
}

a:active {
    color: black;
}

a:hover {
    color: black;
}

.link {
    color: black;
}

.h1 {
    font-size: 1.32rem;
    line-height: 1.34rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
}

.h2 {
    font-size: 1.24rem;
    line-height: 1.26rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
}

.h3 {
    font-size: 1.24rem;
    line-height: 1.26rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
}

.h4 {
    font-size: 1.1rem;
    line-height: 1.12rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
}

.h5 {
    font-size: 1.1rem;
    line-height: 1.12rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
}

.title {
    font-size: 0.88rem;
    line-height: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.sub-title {
    font-size: 0.84rem;
    line-height: 0.86rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.caption {
    font-size: 0.76rem;
    line-height: 0.78rem;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
}

.container {
    padding: 20px;
}

.table {
    padding: 20px;
    background-color: white;
}

.modal-info-block {
    p {
        margin: 0px;
        padding: 0px;
        margin-bottom: 4px;
    }

    margin-bottom: 12px;
}

.ant-input[disabled] {
    color: #8e8e8e !important;
}
